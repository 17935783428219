import React from "react";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Loader } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { View, Button, Flex } from "@aws-amplify/ui-react";

function FaceLiveness({ faceLivenessAnalysis, bearer, callback }) {
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [cameraFacing, setCameraFacing] = useState("user"); // 'user' for front camera, 'environment' for back camera
  const [isMobile, setIsMobile] = useState(false);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent.toLowerCase()
      );
      setIsMobile(isMobileDevice);
    };
    
    checkMobile();
  }, []);

  const fetchCreateLiveness = async () => {
    try {
      const response = await fetch(
        "https://backend.katsina-revpay.com/api/v1/initiate-liveness",
        {
          headers: {
            Authorization: "Bearer " + bearer,
          },
        }
      );
      const data = await response.json();
      console.log(data.data.session_id);
      setSessionId(data.data.session_id);
      setLoading(false);
    } catch (error) {
      console.error("Error initiating liveness session:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to initiate liveness session. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    fetchCreateLiveness();
  }, []);

  const handleTryAgain = () => {
    setLoading(true);
    setSessionId(null);
    fetchCreateLiveness();
  };

  async function startCamera(facingMode = "user") {
      let currentStream = document.querySelector("video").srcObject;
      if (currentStream) {
          currentStream.getTracks().forEach(track => track.stop());
      }

      const constraints = {
          video: { facingMode }
      };

      try {
          currentStream = await navigator.mediaDevices.getUserMedia(constraints);
          document.querySelector("video").srcObject = currentStream;
      } catch (error) {
          console.error("Error accessing camera:", error);
      }
  }
  const toggleCamera = () => {
    var cameralFacing = cameraFacing === "user" ? "environment" : "user";
    startCamera(cameralFacing);
    setCameraFacing(prevFacing => prevFacing === "user" ? "environment" : "user");
  };

  /*
   * Get the Face Liveness Session Result
   */
  const handleAnalysisComplete = async () => {
    try {
      /*
       * API call to get the Face Liveness Session result
       */
      const response = await fetch(
        "https://backend.katsina-revpay.com/api/v1/liveness-results",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + bearer,
          },
          body: JSON.stringify({ sessionid: sessionId }),
        }
      );
      const data = await response.json();
      console.log(data);
      var backto = callback ? callback : "";
      if (data.message) {
        Swal.fire({
          title: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            if (backto !== 'false' && backto !== false) {
              return window.location.href =
                "https://katsina-revpay.com/" + backto;
            } else {
              window.location.href =
                "https://katsina-revpay.com/";
            }
          }
        });
      } else {
        Swal.fire({
          title: "Registration Successful",
          html: "Your Unique ID is <b>" + data.data.individual_id + "</b>",
          icon: "success",
          confirmButtonColor: "#198754",
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href =
              "https://katsina-revpay.com/" + backto;
          }
        });
      }
    } catch (error) {
      console.error("Error getting liveness results:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to process verification. Please try again.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <View>
          {/* {isMobile && (
            <Flex justifyContent="center" marginBottom="1rem" marginTop="1rem">
              <Button onClick={toggleCamera} variation="primary">
                Switch to {cameraFacing === "user" ? "Back" : "Front"} Camera
              </Button>
            </Flex>
          )} */}
          
          <FaceLivenessDetector
            sessionId={sessionId}
            region="us-west-2"
            onAnalysisComplete={handleAnalysisComplete}
            onError={(error) => {
              console.error(error);
            }}
            onUserCancel={handleTryAgain}
            components={{
              ErrorView: ({ children }) => {
                return (
                  <View
                    as="div"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    maxWidth="100%"
                    padding="1rem"
                    width="20rem"
                    textAlign="center"
                  >
                    {children}
                    <br />
                    <Button onClick={handleTryAgain} variation="primary">
                      Try Again
                    </Button>
                  </View>
                );
              },
            }}
          />
        </View>
      )}
    </>
  );
}

export default FaceLiveness;